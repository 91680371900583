<template>
    <!-- 导航 -->
    <!-- <page-head title="校区报告"/> -->
    <!-- 导航 -->
    <page-head title="校区报告">
        <template #right>
            <!-- <div class="filter_box">
                <el-date-picker
                    v-model="dateTime"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="YYYY/MM/DD"
                    value-format="YYYY/MM/DD"
                    @change="getTopData"
                />
            </div> -->
            <filter-date :date="dateTime" @change="handleChangeDate"></filter-date>
        </template>
    </page-head>
    <content-bg>
        <template v-slot:content>
            <div class="info">
                <div
                    class="common"
                    v-for="item in colorList"
                    :key="item.key"
                    :style="{ background: item.background, boxShadow: item.boxShadow }"
                >
                    <span class="icon"><img :src="item.icon" alt="" /></span>
                    <p class="value">{{ item.value }}</p>
                    <p class="label">{{ item.label }}</p>
                </div>
            </div>
            <div class="table">
                <ul class="tableHeader">
                    <li>校队名称</li>
                    <li>校队人数</li>
                    <li>新增球员</li>
                    <li>累计集训次数</li>
                    <li>累计集训人次</li>
                    <li>选拔人次</li>
                    <li>成才率</li>
                </ul>
                <ul v-for="(item, index) in showData.showList" :key="index" class="row">
                    <li class="tameName">{{ item.team }}</li>
                    <li>{{ item.totalPlayerCount }}</li>
                    <li>{{ item.increasePlayerCount }}</li>
                    <li>{{ item.assembleCount }}</li>
                    <li>{{ item.assemblePlayerCount }}</li>
                    <li>{{ item.selectPlayerCount }}</li>
                    <li class="other">{{ item.yield }}%</li>
                </ul>
            </div>
        </template>
    </content-bg>
    <pagination-com
        v-if="showData.totalPage"
        :current-page="showData.page"
        :total-page="showData.totalPage"
        @changePage="changePage"
    ></pagination-com>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { getCurrentInstance, reactive, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'detail',
    setup() {
        const router = useRouter();
        const route = useRoute();
        const { proxy } = getCurrentInstance();
        const store = useStore();

        const state = reactive({
            colorList: [
                {
                    icon: require('@i/school/icon_teamNum.svg'),
                    background: 'linear-gradient(110.54deg, #A579F4 13.63%, #8752E3 88.76%)',
                    boxShadow: '0px 6px 12px rgba(101, 46, 200, 0.12)',
                    label: '校队总数',
                    key: 'totalTeamCount',
                    value: 0,
                },
                {
                    icon: require('@i/school/icon_playerNum.svg'),
                    background: 'linear-gradient(110.54deg, #FF876C 13.63%, #FF6A48 88.76%)',
                    boxShadow: '0px 6px 12px rgba(247, 97, 70, 0.24)',
                    label: '球员总数',
                    key: 'totalPlayerCount',
                    value: 0,
                },
                {
                    icon: require('@i/school/icon_newTotal.svg'),
                    background: 'linear-gradient(110.54deg, #8ADE8D 13.63%, #64CF68 88.76%)',
                    boxShadow: '0px 6px 12px rgba(112, 212, 116, 0.12)',
                    label: '新增总数',
                    key: 'increasePlayerCount',
                    value: 0,
                },
                {
                    icon: require('@i/school/icon_assembleN.svg'),
                    background: 'linear-gradient(110.54deg, #6389F3 13.63%, #3564EA 88.76%)',
                    boxShadow: '0px 6px 12px rgba(101, 46, 200, 0.12)',
                    label: '集训次数',
                    key: 'assembleCount',
                    value: 0,
                },
                {
                    icon: require('@i/school/icon_assembleP.svg'),
                    background: 'linear-gradient(110.54deg, #FF696C 13.63%, #F72125 88.76%)',
                    boxShadow: '0px 6px 12px rgba(247, 97, 70, 0.24)',
                    label: '集训人次',
                    key: 'assemblePlayerCount',
                    value: 0,
                },
                {
                    icon: require('@i/school/icon_selection.svg'),
                    background: 'linear-gradient(110.54deg, #FFC75B 13.63%, #FDBA36 88.76%)',
                    boxShadow: '0px 6px 12px rgba(254, 188, 60, 0.12)',
                    label: '选拔人次',
                    key: 'selectPlayerCount',
                    value: 0,
                },
                {
                    icon: require('@i/school/icon_yield.svg'),
                    background: 'linear-gradient(270deg, #685BFF 0%, #8D84FF 100%)',
                    boxShadow: '0px 12px 20px rgba(70, 40, 255, 0.2)',
                    label: '成材率',
                    key: 'yield',
                    value: 0,
                },
            ],

            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 10,
            },

            showData: {},

            dataList: [],

            dateTime: [
                proxy.$moment().subtract(6, 'months').format('YYYY/MM/DD'),
                proxy.$moment().format('YYYY/MM/DD')
            ],
        });

        // 获取校区数据
        const getTopData = () => {
            const params = {
                campusId: route.query.id,
                start: state.dateTime[0],
                end: state.dateTime[1],
            };
            proxy.$server.getCampusAnalysis(params).then((res) => {
                if (res.code === 200) {
                    for (let i = 0; i < state.colorList.length; i++) {
                        if (res.data[state.colorList[i].key]) {
                            state.colorList[i].value = res.data[state.colorList[i].key];
                        } else {
                            state.colorList[i].value = 0;
                        }
                    }
                }
            });
            getCampusAnalysisDetail(params);
        };
        // 获取校区详情数据
        const getCampusAnalysisDetail = (params) => {
            proxy.$server.getCampusAnalysisDetail(params).then((res) => {
                if (res.code === 200) {
                    state.dataList = res.data;
                    state.showData = proxy.$utils.paginationFn(
                        state.dataList,
                        state.page.pageNum,
                        state.page.pageSize
                    );
                }
            });
        };
        // 时间筛选变化时
        const handleChangeDate = (date) => {
            state.dateTime = date;
            getTopData();
        }

        onMounted(() => {
            getTopData();
        });

        return {
            ...toRefs(state),
            getTopData,
            handleChangeDate
        };
    },
};
</script>

<style scoped lang="scss">
.info {
    display: flex;
    margin-left: 30px;
    margin-top: 42px;
}

.common {
    width: 170px;
    height: 170px;
    border-radius: 6px;
    margin-right: 18px;
    color: #fff;
    position: relative;

    .icon {
        width: 62px;
        height: 62px;
        display: inline-block;
        position: absolute;
        left: 16px;
        top: 16px;

        img {
            width: 100%;
        }
    }

    .value,
    .label {
        text-align: right;
        padding-right: 12px;
        box-sizing: border-box;
    }

    .value {
        font-weight: 700;
        font-size: 44px;
        line-height: 55px;
        font-family: akrobatBold;
        padding-top: 60px;
    }

    .label {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
    }
}

.table {
    margin-top: 40px;
    padding-right: 25px;
    padding-left: 25px;
    ul {
        height: 90px;
        line-height: 90px;
        display: flex;
        padding-left: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        li {
            flex: 1;

            &.tameName {
                font-size: 28px;
                font-family: PingFangMedium;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &.tableHeader {
            color: rgba(255, 255, 255, 0.6);
            font-size: 26px;
        }
    }

    .row {
        font-size: 30px;

        color: #fff;
        li {
            font-family: akrobatBold;
        }
        .other {
            color: #f7ff29;
        }
    }
}
::v-deep.navigation-mian {
    width: 1410px;
    position: relative;
    z-index: 10;
}
::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
.filter-date {
    margin-left: auto;
    margin-right: 0;
}
</style>
